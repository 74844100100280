<template>
    <div class="login-page" v-if="errorText">
        <AuthLoginHeader />
        <SSOCallbackError class="error" :errorText="errorText" />
        <AuthLoginFooter />
    </div>
    <p v-else>{{ $t('SSOCallback.authenticating') }}</p>

</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import axiosInstance from '@/services/interceptor';
import { useStore } from 'vuex';

import AuthLoginHeader from '@/views/auth-login/AuthLoginHeader.vue';
import AuthLoginFooter from '@/views/auth-login/AuthLoginFooter.vue';
import SSOCallbackError from '@/views/sso-callback/SSOCallbackError.vue';

const router = useRouter();
const store = useStore();
const errorText = ref(null);


const handleAuthCode = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
        try {
            const response = await axiosInstance.post(`/auth/verify-sso/`, {
                'code': code,
            });
            if (response.data.success) {
                handleSuccessfulAuth(response);
            } else {
                errorText.value = response.data.error || 'Failed to acquire token';
                console.error('Failed to acquire token:', response.data);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                errorText.value = error.response.data.error_locale;
            } else {
                errorText.value = 'An unexpected error occurred.';
            }
            console.error('Error during authentication:', error);
        }
    } else {
        errorText.value = 'No authorization code provided.';
    }
};

async function handleSuccessfulAuth(response) {
    // Handle successful authentication
    // Clear the code from the URL to prevent infinite redirection
    window.history.replaceState({}, document.title, "/");

    // Commit the mutations to update the Vuex store
    store.commit('setAuthUser', {
        authUser: response.data.authenticatedUser,
        isAuthenticated: response.data.success,
    });
    store.commit('updateToken', {
        newToken: response.data.access,
        refreshToken: response.data.refresh,
    });

    // Optionally, store items in sessionStorage
    sessionStorage.setItem("isAuthenticated", JSON.stringify(response.data.success));
    sessionStorage.setItem("authUser", JSON.stringify(response.data.authenticatedUser));
    sessionStorage.setItem("jwt", response.data.access);
    sessionStorage.setItem("refresh", response.data.refresh);

    // Redirect to another page
    router.push('/dossiers');
}


onMounted(() => {
    handleAuthCode();
});
</script>

<style scoped>
.login-page {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.error {
    margin: 2.5rem;
    justify-content: center;
}

</style>