import axiosInstance from "@/services/interceptor";

export default {
  async sendLoginCredentials(context, payload) {
    try {
      const response = await axiosInstance.post("auth/login-mfa/", payload);
      const setCookieHeader = response.headers["set-cookie"];

      if (!response.data.success) {
        return Promise.reject(response.data);
      } else {
        return response.data; // Return the response data
      }
    } catch (error) {
      if (error.response) {
        return Promise.reject(error.response.data);
      } else if (error.request) {
        return Promise.reject({ message: "No response received from server" });
      } else {
        return Promise.reject({ message: error.message });
      }
    }
  },

  async verifyMFA({ commit }, payload) {
    try {
      const response = await axiosInstance.post("auth/verify-otp/", payload);

      if (response.data.success) {
        commit("setAuthUser", {
          authUser: response.data.authenticatedUser,
          isAuthenticated: response.data.success,
        });
        commit("updateToken", {
          newToken: response.data.access,
          refreshToken: response.data.refresh,
        });

        // Sets data into variable, stores it there as a string
        const authUser = JSON.stringify(response.data.authenticatedUser);
        const isAuthenticated = JSON.stringify(response.data.success);

        // Stores items in sessionStorage, so on page refresh they can be stored back into the vuex store
        sessionStorage.setItem("isAuthenticated", isAuthenticated);
        sessionStorage.setItem("authUser", authUser);
        sessionStorage.setItem("jwt", response.data.access);
        sessionStorage.setItem("refresh", response.data.refresh);
      }

      return response.data;


    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },

    logout(context) {
      sessionStorage.removeItem("isAuthenticated");
      sessionStorage.removeItem("jwt");
      sessionStorage.removeItem("refresh");
      sessionStorage.removeItem("authUser");

      context.commit("setAuthUser", {
        authUser: {},
        isAuthenticated: "",
      });
      context.commit("removeToken", {
        jwt: "",
        refresh: "",
      });
    },

  //function that automatically logs user back in on refresh
  autoLogin(context) {
    // gets data from session storage
    const isAuthenticated = sessionStorage.getItem("isAuthenticated");
    const jwt = sessionStorage.getItem("jwt");
    const refresh = sessionStorage.getItem("refresh");
    const authUser = sessionStorage.getItem("authUser");

    //turns stored data from string into boolean and an object respectively
    const booleanIsAuthenticated = JSON.parse(isAuthenticated);
    const objectAuthUser = JSON.parse(authUser);

    if (isAuthenticated && jwt && refresh && authUser) {
      context.commit("setAuthUser", {
        authUser: objectAuthUser,
        isAuthenticated: booleanIsAuthenticated,
      });
      context.commit("updateToken", {
        newToken: jwt,
        refreshToken: refresh,
      });
    }
  },
  refreshToken(context, { newToken, refreshToken }) {
    context.commit("updateToken", {
      newToken: newToken,
      refreshToken: refreshToken,
    });
  },
};
