<template>
    <div class="page-wrapper">
        <BreadcrumbsTitle></BreadcrumbsTitle>

        <Card class="square-card">

            <template #content>

                <form class="form" @submit.prevent="checkForErrors(newSelection)">
                    <div class="form-field">
                        <h4 class="form-title">{{ $t('addSelectionView.precontractual-title') }}</h4>
                        <div v-if="newSelection.precontractual.length > 0" class="show-items">
                            <p>{{ $t('addSelectionView.currently-selected') }}</p>

                            <span v-for="item in newSelection.precontractual">
                                <!-- Adds a ',' after the first item -->
                                <span v-if="newSelection.precontractual.indexOf(item) === 0"></span>
                                <span v-else>, </span>

                                {{ $t('riskDetailsTable.' + item) }}</span>
                        </div>

                        <MultiSelect v-model="newSelection.precontractual" :options="precontractualList"
                            :placeholder="$t('addSelectionView.precontractual-risks')" :maxSelectedLabels="0"
                            class="w-full md:w-20rem" id="precon-select">
                            <template #option="slotProps">
                                <p>{{ $t('riskDetailsTable.' + slotProps.option) }}</p>
                            </template>
                        </MultiSelect>
                    </div>

                    <div class="form-field">
                        <h4 class="form-title">{{ $t('addSelectionView.contractual-title') }}</h4>
                        <div v-if="newSelection.contractual.length > 0" class="show-items">
                            <p>{{ $t('addSelectionView.currently-selected') }}</p>

                            <span v-for="item in newSelection.contractual">
                                <!-- Adds a ',' after the first item -->
                                <span v-if="newSelection.contractual.indexOf(item) === 0"></span>
                                <span v-else>, </span>

                                {{ $t('riskDetailsTable.' + item) }}</span>
                        </div>

                        <MultiSelect v-model="newSelection.contractual" :options="contractualList"
                            :placeholder="$t('addSelectionView.contractual-risks')" :maxSelectedLabels="0"
                            class="w-full md:w-20rem" id="con-select">
                            <template #option="slotProps">
                                <p>{{ $t('riskDetailsTable.' + slotProps.option) }}</p>
                            </template>
                        </MultiSelect>
                    </div>

                    <div class="form-field">
                        <h4 class="form-title">{{ $t('addSelectionView.profile') }}</h4>
                        <Dropdown v-model="newSelection.profile" :options="profileList" optionLabel="name"
                            :placeholder="$t('addSelectionView.select-profile')" option-value="id"
                            class="w-full md:w-14rem" />
                        <p v-if="noProfileSelected" class="error">{{ $t('addSelectionView.noProfile') }}</p>
                    </div>

                    <div class="form-field">
                        <h4 class="form-title">{{ $t('addSelectionView.name') }}</h4>
                        <textarea type="text" v-model="newSelection.name" :placeholder="$t('addSelectionView.name')" />
                        <p v-if="nameAlreadyExists" class="error">{{ $t('addSelectionView.nameExists') }}</p>
                        <p v-if="nameIsEmpty" class="error">{{ $t('addSelectionView.emptyError') }}</p>

                    </div>

                    <TheButton class="form-button">
                        <template #text>{{ $t('addSelectionView.submit') }}</template>
                    </TheButton>
                </form>


            </template>
        </Card>
    </div>
</template>

<script setup>
import TheButton from '@/components/ui/TheButton.vue';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import Card from 'primevue/card';

import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axiosInstance from '@/services/interceptor';
import { registerRuntimeCompiler } from 'vue';

import riskDetailsChecklist from "../assets/JSON/riskDetailsChecklist.json"
import profilesJSON from "../assets/JSON/profiles.json"


const contractualList = riskDetailsChecklist.contractual;
const precontractualList = riskDetailsChecklist.precontractual;
const profileList = profilesJSON.profiles;
const router = useRouter();

const newSelection = ref({
    precontractual: [],
    contractual: [],
    profile: null,
    name: '',
})
const nameAlreadyExists = ref(false);
const noProfileSelected = ref(false);
const nameIsEmpty = ref(false);

// calls all error handling functions
// async because it calls other async functions, the checks are done in order
// every single check is needed, so 
async function checkForErrors(selection) {
    await checkIfNameIsEmpty(selection);
    await checkIfNoProfileSelected(selection);
    await checkIfNameAlreadyExists(selection);

    // if one of the error values == true, returns, else it calls addSelection
    if (nameAlreadyExists.value || noProfileSelected.value || nameIsEmpty.value) {
        return
    } else {
        await addSelection(selection);
    }
}

async function checkIfNameIsEmpty(selection) {
    if (selection.name == '') {
        nameIsEmpty.value = true;
    } else {
        nameIsEmpty.value = false;
    }
}

async function checkIfNameAlreadyExists(selection) {
    const profiles = await getProfiles();

    // Reset the error value before checking
    nameAlreadyExists.value = false;

    // Check if the selection.name is already in the profiles
    profiles.forEach((profile) => {
        if (profile.name === selection.name) {
            nameAlreadyExists.value = true;
        }
    });
}

async function checkIfNoProfileSelected(selection) {
    if (selection.profile === null) {
        noProfileSelected.value = true;
    } else {
        noProfileSelected.value = false;
    }
}

async function addSelection(selection) {
    axiosInstance.post('/profiles/', selection)
        .then((response) => {
            // if component has been loaded in this path /my-selections
            if (router.currentRoute.value.fullPath === "/selections") {
                // refresh page
                window.location.reload();
            } else {
                // else, go to that path
                router.push('/selections')
            }
        })
        .catch((response) => {
        })
        .then(() => {
        })
}

function getProfiles() {
    return axiosInstance.get('/profiles/')
        .then((response) => {
            return response.data; // This return is now captured by the `getProfiles` function
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
}


</script>

<style scoped>
.show-items {
    color: grey;
    font-size: small;
}

/* FORM */
.form,
.form-empty,
.form-undefined {
    color: #1A224C;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    max-width: 35rem;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-button {
    margin-top: 1rem;
}

.form-title {
    font-weight: 450;
    color: var(--sec-dark-purple);
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}
</style>