<template>
    <div class="forgot-password-page">
        <Card class=card>
            <template #header>
                <h1 class="title">{{ $t('ForgotPasswordView.forgot-password') }}</h1>

            </template>

            <template #content>
                <form @submit.prevent="handleSubmit" autocomplete="off">
                    <p>{{ $t('ForgotPasswordView.enter-email') }}</p>
                    <div class="form-field">
                        <label for="email">{{ $t('ForgotPasswordView.email') }}</label>
                        <InputText class="input-field" autocomplete="off" type="email" v-model="emailObject.email"
                            :placeholder="$t('ForgotPasswordView.email')" />
                        <p v-if="emailIsValid === false" class="error">{{ $t('ForgotPasswordView.wrong-email') }}</p>
                    </div>
                    <div class="button-field">
                        <Button icon="pi pi-arrow-left" class="standard-button" :label="$t('ForgotPasswordView.back')"
                            @click="$router.go(-1)" />
                        <Button icon="pi pi-send" class="standard-button" :label="$t('ForgotPasswordView.send')"
                            @click="checkEmail()" :loading="postIsLoading" />
                    </div>
                    <p v-if="successfulPost === true || successfulPost === false"
                        :class="{ success: successfulPost, error: !successfulPost }">{{ postResponse }}</p>

                </form>

            </template>
        </Card>
    </div>
</template>

<script setup>

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Card from 'primevue/card';

import { ref } from 'vue';
import axios from 'axios';
import i18n from '@/i18n';

const uninterceptedAxiosInstance = axios.create();

const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
const emailObject = ref({
    "email": '',
});
const postIsLoading = ref(false);
const successfulPost = ref(null);
const postResponse = ref('');
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const emailIsValid = ref(null);
const { t } = i18n.global;


function requestPasswordChange() {
    postIsLoading.value = true;

    uninterceptedAxiosInstance.post(BASE_URL + 'auth/password_reset/', emailObject.value)
        .then((response) => {
            postIsLoading.value = false;
            successfulPost.value = true;
            postResponse.value = t('ForgotPasswordView.success')
        })
        .catch((error) => {
            setTimeout(() => {
                postIsLoading.value = false;
                successfulPost.value = true;
                postResponse.value = t('ForgotPasswordView.success')
            }, 1000);
        })
};

function checkEmail() {
    if (emailObject.value.email.match(emailRegex)) {
        emailIsValid.value = true;
        requestPasswordChange();
    } else {
        emailIsValid.value = false;
        return
    }
}
</script>

<style scoped>
.forgot-password-page {
    display: flex;
    justify-content: center;
}

.card {
    flex-grow: 1;
    max-width: 750px;

}


.error {
    margin-top: 1rem;
    color: red;
}

.success {
    margin-top: 1rem;
    color: green;
}

.form-field {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    text-align: start;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.standard-button {
    background-color: var(--sec-dark-purple);
    color: white;
}

.standard-button:disabled {
    background-color: var(--sec-light-purple);
    color: white;
}

.standard-button:hover {
    background-color: var(--main-dark-purple);
    color: white;
}

/* :deep(.p-button.standard-button) {
    background-color: #1A224C;
    border-color: #1A224C;
    color: white;
}

:deep(.p-button.standard-button:hover) {
    background-color: #bfc7ff;
    border-color: #bfc7ff;
    color: #1A224C;
} */

.button-field {
    display: flex;
    justify-content: space-between;
}

.title {
    padding: 1.5rem;
}

.form-field {
    width: 100%;
    box-sizing: border-box;
}
</style>