<template>
    <div class="full-page">
        <breadcrumbs-title></breadcrumbs-title>

        <Card>
            <template #content>
                <form class="form" @submit.prevent="checkForErrors(newKeywords)">

                    <div class="form-field">
                        <h4 class="form-title">{{ $t('addKeywords.name') }}</h4>
                        <textarea type="text" v-model="newKeywords.name" :placeholder="$t('addKeywords.name')"
                            @keydown="nameAlreadyExists = false" />
                        <p v-if="nameAlreadyExists && attemptedToSend" class="error">{{ $t('addKeywords.nameExists') }}
                        </p>
                        <p v-if="attemptedToSend && newKeywords.name === ''" class="error">{{
                            $t('addKeywords.emptyError') }}</p>

                    </div>

                    <div class="form-field">
                        <h4 class="form-title">{{ $t('addKeywords.keywords') }}</h4>
                        <Chips v-model="newKeywords.keywords" class="chips" separator="," />
                        <p v-if="newKeywords.keywords.length < 1 && attemptedToSend" class="error">{{
                            $t('addKeywords.emptyError') }}</p>

                    </div>

                    <TheButton class="form-button">
                        <template #text>{{ $t('addKeywords.submit') }}</template>
                    </TheButton>
                </form>

            </template>
        </Card>
    </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import TheButton from '@/components/ui/TheButton.vue';
import Chips from 'primevue/chips';
import Card from 'primevue/card';

import { ref } from 'vue';
import { useRouter } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';

const router = useRouter();

const newKeywords = ref({
    keywords: [],
    name: '',
})
const nameAlreadyExists = ref(false);
const attemptedToSend = ref(false);


// Function to get keywords from the server
async function getKeywords() {
    return axiosInstance.get('/keywords/')
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Error fetching keywords:", error);
            return [];
        });
}


async function checkForEmptyFields(keywords) {
    if (!keywords || keywords.keywords.length < 1 || keywords.name === '') {
        return true;
    } else {
        return false;
    }
}

async function checkForDuplicateName(keywords) {
    const existingKeywords = await getKeywords();

    // check if 'name' field of keywords object exists in the existingKeywords array
    if (existingKeywords.some(item => item.name === keywords.name)) {
        nameAlreadyExists.value = true;
        return true;
    } else {
        nameAlreadyExists.value = false;
    }
    return false;
}

async function checkForErrors(keywords) {
    attemptedToSend.value = true;
    let fieldsAreEmpty = await checkForEmptyFields(keywords);
    if (fieldsAreEmpty) {
        return;
    }
    let duplicateName = await checkForDuplicateName(keywords);
    if (duplicateName) {
        return;
    }
    addKeywords(keywords);
}

function addKeywords(keywords) {
    axiosInstance.post('keywords/', keywords)
        .then((response) => {
            router.push('/keywords')
        })
        //handle error
        .catch((error) => {
            devLog(error)
        })
        //always executed
        .then(() => {
        });
}

</script>

<style scoped>
.show-items {
    color: grey;
    font-size: small;
}

/* FORM */
.form,
.form-empty,
.form-undefined {
    color: #1A224C;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    max-width: 35rem;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* user should not be able to scroll in text field */
}

.form-button {
    margin-top: 1rem;
}

.form-title {
    font-weight: 450;
    color: var(--sec-dark-purple);
}

.error {
    color: red;
}

textarea {
    width: 20rem;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    /* overflow-y: scroll; */
    height: 3rem;
    overflow-y: hidden;

}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);

}

:deep(.p-chips-input-token) {
    width: 40rem;
}
</style>