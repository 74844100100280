<template>
    <div class="page-wrapper">
        <BreadcrumbsTitle />
        <Card class="flex-wrapper square-card" v-if="!filesInDossier">
            <template #title>
                <div class="header-container">
                    <p>{{ $t('DashboardUploadView.upload') }}</p>
                    <div class="upload-button-zone">
                        <the-dark-button :loading=isLoading @click="handleUpload($event)">
                            {{ $t("DashboardUploadView.upload-tender") }}
                        </the-dark-button>

                        <p v-if="errorUpload && uploadFilesData.files < 1" class="error">{{
            $t("DashboardUploadView.error.file") }}</p>

                    </div>
                </div>
            </template>
            <template #content>

                <div class="dropzone-container" @dragover.prevent="dragOver" @dragleave.prevent="dragLeave"
                    @drop.prevent="drop">
                    <input type="file" id="fileInput" class="hidden-input" multiple="multiple"
                        @change.prevent="onChange(event)" ref="file" accept=".pdf" />
                    <label for="fileInput" class="file-label">
                        <div v-if="!isDragging">
                            {{ $t("DashboardUploadView.drop-files") }} {{ $t("DashboardUploadView.or") }} <u
                                id="upload-link"> {{
            $t("DashboardUploadView.click-here") }} </u>
                            {{ $t("DashboardUploadView.to-upload") }}.
                        </div>
                        <div v-else="isDragging">{{ $t("DashboardUploadView.release-drop") }}.</div>
                    </label>
                </div>

                <Card class="upload-card" v-for="file, index in uploadFilesData.files"
                    v-if="uploadFilesData.files.length >= 1">
                    <template #content>
                        <div class="upload-container">

                            <div class="form-field">
                                <InputText class="input-text" v-model="uploadFilesData.names[index]"
                                    :placeholder="t('DashboardUploadView.name')" />
                                <p class="error"
                                    v-if="errorUpload && uploadFilesData.files[index].type !== 'application/pdf'">
                                    {{ $t("DashboardUploadView.error.pdf") }}
                                </p>
                                <p class="error" v-if="errorUpload && uploadFilesData.files[index].size > maxSize">
                                    {{ $t("DashboardUploadView.error.size") }}
                                </p>
                                <p class="error" v-else-if="errorUpload && uploadFilesData.names[index].length < 1">
                                    {{ $t("DashboardUploadView.error.name-short") }}

                                </p>
                                <p class="error" v-else-if="errorUpload && uploadFilesData.names[index].length > 40">
                                    {{ $t("DashboardUploadView.error.name-long") }}

                                </p>
                                <p class="error"
                                    v-else-if="errorUpload && duplicateFileNames.includes(uploadFilesData.names[index])">
                                    {{ $t("DashboardUploadView.error.duplicate") }}
                                </p>
                            </div>
                            <div class="form-field">
                                <Dropdown v-model="uploadFilesData.types[index]" :options="typeOptions"
                                    optionLabel="name" optionValue="code" :placeholder="t('DashboardUploadView.type')">
                                </Dropdown>
                                <p class="error" v-if="errorUpload && uploadFilesData.types[index] === null">
                                    {{ $t("DashboardUploadView.error.no-selection") }}
                                </p>

                            </div>
                            <div class="form-field">
                                <Checkbox type="radio" :value="index" v-model="qualificationFileArray"
                                    @change="handleCheckboxChange(index)" />
                                <label for="qualification" class="ml-2">{{ $t('DashboardUploadView.qualification')
                                    }}</label>
                            </div>
                            <Button icon="pi pi-times" severity="danger" rounded outlined aria-label="Cancel"
                                @click="removeFile(index)" />
                        </div>

                    </template>
                </Card>


            </template>
        </Card>
        <Card class="flex-wrapper square-card" v-else>
            <template #title>
                <p>{{ $t('DashboardUploadView.error-upload') }}</p>
            </template>
            <template #content>
                <p>{{ $t('DashboardUploadView.error-upload-description') }}</p>
            </template>
        </Card>
        <list-of-documents class="square-card"></list-of-documents>
    </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import ListOfDocuments from '@/views/dashboard-upload/ListOfDocuments.vue';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TheDarkButton from '@/components/ui/TheDarkButton.vue';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";
import Checkbox from 'primevue/checkbox';

import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from "primevue/useconfirm";


import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import axiosInstance from '@/services/interceptor';
import i18n from '@/i18n';
import { devLog } from '@/helpers/helpers';

const { t } = i18n.global;

const toast = useToast();
const route = useRoute();
const router = useRouter();
const confirm = useConfirm();

const maxSize = 50 * 1024 * 1024; // 50MB in bytes
//variables used in the dropzone for styling/reactivity/error-handling purposes
const isDragging = ref(false);
const fileSelected = ref(false);
const errorUpload = ref(false);
const isLoading = ref(false);
const errorMessagePDF = ref(false);

// currently selected uploadfiles will be stored here
let upload = [];

const uploadFilesData = ref({
    // all files will be stored here
    files: [],
    names: [],
    types: [],
    dossier: route.params.dossierId
});
const typeOptions = [
    {
        name: "Selectieleidraad",
        code: 5
    },
    {
        name: "Administratief bestek",
        code: 2
    },
    {
        name: "Technisch bestek",
        code: 3
    },
    {
        name: "Integraal bestek",
        code: 1
    },
    {
        name: "Andere documenten",
        code: 4
    }
]

const duplicateFileNames = ref([]);
// Array to store the index of the selected file for qualification
const qualificationFileArray = ref([]);
// Computed property to always reflect the first item in the array
const qualificationFileIndex = computed(() => {
    return qualificationFileArray.value.length > 0 ? qualificationFileArray.value[0] : null;
}); 


// get dossier of route.params.dossierId, and if said dossier has files, return true, else return false and store in filesInDossier

const filesInDossier = ref(false);



// Add a new method to handle the radio button change
const handleCheckboxChange = (index) => {
    // qualificationFIleArray is an array that can only be filled with one value, so we can just set the first value to the index
    // it also has to be able to have no value, so we can set it to an empty array

    if (qualificationFileArray.value.length >= 2) {
        qualificationFileArray.value.shift();
    }

};

//functions that change the above values
function dragOver() {
    isDragging.value = true;
}
function dragLeave() {
    isDragging.value = false;
}

function removeFile(index) {
    // removes all data from every array with selected index
    uploadFilesData.value.files.splice(index, 1);
    uploadFilesData.value.names.splice(index, 1);
    uploadFilesData.value.types.splice(index, 1);
}

//gets called when user drops file
function drop(e) {
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
        //file gets pushed in upload, 
        upload.push(e.dataTransfer.files[i]);

        let currentUploadFile = e.dataTransfer.files[i];
        uploadFilesData.value.files.push(
            currentUploadFile
        );
        uploadFilesData.value.names.push(
            currentUploadFile.name
        );
        // if user uploads more than 1 file at a time, then all files have the type 4
        if (e.dataTransfer.files.length > 1) {
            uploadFilesData.value.types.push(
                4
            );
        } else {
            uploadFilesData.value.types.push(
                null
            );
        }
    }

    fileSelected.value = true;
    isDragging.value = false;
}

//gets called when user selects file
function onChange() {
    //file gets stored in upload, 
    for (let i = 0; i < document.querySelector(".hidden-input").files.length; i++) {
        let currentUploadFile = document.querySelector(".hidden-input").files[i];

        uploadFilesData.value.files.push(
            currentUploadFile
        );
        uploadFilesData.value.names.push(
            currentUploadFile.name
        );
        // if user uploads more than 1 file at a time, then all files have the type 4
        if (document.querySelector(".hidden-input").files.length > 1) {
            uploadFilesData.value.types.push(
                4
            );
        } else {
            uploadFilesData.value.types.push(
                null
            );
        }
    }
}

function checkDuplicateFileNames() {
    const names = uploadFilesData.value.names;
    const duplicates = names.filter((name, index) => names.indexOf(name) !== index);
    duplicateFileNames.value = [...new Set(duplicates)];
    if (duplicates.length > 0) {
        errorUpload.value = true;
        return true;  // Return true if duplicates were found
    }
    errorUpload.value = false;
    return false;  // Return false if no duplicates were found
}

function checkIfFilesSelected() {
    if (uploadFilesData.value.files.length < 1) {
        errorUpload.value = true;
        return true;
    }
    return false;
}

function checkIfTypesAssigned() {
    for (const index in uploadFilesData.value.files) {
        if (uploadFilesData.value.types[index] === null) {
            errorUpload.value = true;
            return true;
        }
    }
    return false;
}

function checkIfNamesValid() {
    for (const index in uploadFilesData.value.names) {
        if (uploadFilesData.value.names[index].length > 40 || uploadFilesData.value.names[index].length < 1) {
            errorUpload.value = true;
            return true;
        }
    }
    return false;
}

function checkIfFilesArePDFs() {
    for (const file of uploadFilesData.value.files) {
        if (file.type !== 'application/pdf') {
            errorUpload.value = true;
            devLog('no pdf selected')
            errorMessagePDF.value = true;
            return true;  // Return true if a non-PDF file was found
        }
    }
    return false;  // Return false if all files are PDFs
}

function checkFilesSize() {
    for (const file of uploadFilesData.value.files) {
        if (file.size > maxSize) {
            errorUpload.value = true;
            return true;  // Return true if a file is larger than 50MB
        }
    }
    return false;  // Return false if all files are less than or equal to 50MB
}

async function handleUpload(event) {
    // first check for errors
    if (await checkForErrors(event)) {
        return;
    }

    if (await checkIfQualificationExists(event)) {
        showPopupConfirmation(event);
        return;
    }

    uploadFile();
}

// checks for all errors before uploading
async function checkForErrors(event) {
    if (checkDuplicateFileNames() ||
        checkIfFilesSelected() ||
        checkIfTypesAssigned() ||
        checkIfNamesValid() ||
        checkFilesSize() ||
        checkIfFilesArePDFs()) {
        return true;
    }
}

function showMessage(severity, summary, detail, life) {
    toast.add({ severity: severity, summary: summary, detail: detail, life: life });
};


async function getQualification() {
    try {
        const response = await axiosInstance.get('/qualifications/?dossier=' + route.params.dossierId);
        return response.data.length >= 1;
    } catch (error) {
        devLog(error);
        return false;
    }
}

async function checkIfQualificationExists(event, data) {
    // is user has selected a qualification, and there is an already existing qualification
    if (qualificationFileIndex.value !== null && await getQualification()) {
        return true;
    } else {
        return false;
    }
}

function showPopupConfirmation(event) {
    confirm.require({
        target: event.currentTarget,
        message: t('DashboardUploadView.qualification-message'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: t('DashboardUploadView.accept'),
        rejectLabel: t('DashboardUploadView.reject'),
        acceptClass: 'confirm-delete',
        accept: () => {
            toast.add({ severity: 'info', summary: t('DashboardUploadView.success'), detail: t('DashboardUploadView.delete-confirm'), life: 3000 });
            uploadFile();

        },
        reject: () => {
            toast.add({ severity: 'error', summary: t('DashboardUploadView.cancel'), detail: t('DashboardUploadView.delete-cancel'), life: 3000 });
        }
    });
}



async function uploadFile() {

    // Initialize used_for_qualifications with false values
    uploadFilesData.value.used_for_qualifications = Array(uploadFilesData.value.names.length).fill(false);
    // Set the used_for_qualifications value to true for the file selected for qualification
    if (qualificationFileIndex.value !== null) {
        uploadFilesData.value.used_for_qualifications[qualificationFileIndex.value] = true;
    }

    isLoading.value = true;
    axiosInstance
        .post("documents/", uploadFilesData.value, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            //store.dispatch('initiateCheckingForSummariesInDossiers');

            // if response.status is 201, and if all types are not 4
            if (response.status === 201) {
                //on success and if all types are not 4
                router.push('/')
            } else {
                // if error
                showMessage("error", "Mislukt", response.data.message, 6000);
            }
        })
        .catch((error) => {
            // on error with same name
            devLog(error);
            showMessage("error", "Mislukt", "Er liep iets mis, probeer dit later opnieuw.", 6000);

        })
        .then((response) => {
            // resetting all values
            fileSelected.value = false;
            isLoading.value = false;
            upload = null
            uploadFilesData.value.files = [];
            uploadFilesData.value.names = [];
            uploadFilesData.value.types = [];
        });
}

const fetchDossierFiles = async () => {
    const dossierId = route.params.dossierId;
    try {
        const response = await axiosInstance.get(`documents/?dossier=${dossierId}`);
        filesInDossier.value = response.data.length > 0;
    } catch (error) {
        console.error('Error fetching dossier files:', error);
        filesInDossier.value = false;
    }
};


//* This is code that works together with dossiers in store, now we moved away from that logic
//* but we're keeping the code in case we'll ever need it again
// function updateDossier(dossier) {
//     // change dossier.changed to true
//     dossier.summary_status = 'needs_update';
//     dossier.warnings_status = 'needs_update';
//     devLog('dossier,', dossier)
//     axiosInstance.put('/dossiers/' + route.params.dossierId + '/', dossier)
//         .then((response) => {
//             router.push('/')
//         })
//         .catch((error) => {
//             devLog(error)
//         })
//         .then(() => {
//             // isLoading.value = false
//         })
// }

// function getDossier() {
//     axiosInstance.get('/dossiers/' + route.params.dossierId + '/')
//         .then((response) => {
//             //updateDossier(response.data)
//         })
//         .catch((error) => {
//             devLog(error)
//         })
//         .then(() => {
//             // isLoading.value = false
//         })
// }

onMounted(() => {
    fetchDossierFiles();
});

</script>

<style scoped>
.flex-wrapper {
    display: flex;
    flex-wrap: wrap;
}

:deep(.p-card-title),
:deep(.p-card-content) {
    font-size: 14px;
    color: var(--main-dark-purple);
}

:deep(.p-inputtext).input-text {
    width: 25rem;
}

.square-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    border-radius: 0;
    flex-grow: 1;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.upload-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upload-card {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.header-container {
    display: flex;
    justify-content: space-between;
}

/* DROPZONE */

.dropzone-container {
    padding: 2rem;
    background: #f1f3ff;
    border: 5px dotted #95a0ec;
    width: 100%;
    margin-top: 1rem;
}

.dropzone-error,
.filename-input {
    width: 50%;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    flex-direction: column;
}

.filename-input {
    margin-top: 2.5rem;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    font-size: 14px;
    display: block;
    cursor: pointer;
}

.button {
    margin-bottom: 1rem;
    margin-top: 2.5rem;
}

.error {
    margin-top: 0.5rem;
    color: red;
    font-weight: 300;
}
</style>