<template>
    <RightClickRisksFunctionality :data="rightClickData" :event="rightClickEvent" @risk-edited="emit('updateRisks')"
        :selected-risks="selectedRisks" @deleteRisk="handleBulkDelete">
    </RightClickRisksFunctionality>

    <div v-show="props.sourcesAreVisible" v-if="props.filteredRisks.length !== 0"
        v-for="risk, index in props.filteredRisks" :key="risk.id" :class="showSelectedRisk(risk)"
        @click="addRiskToSelection($event, risk)" class="risks-body">
        <!-- <Divider /> -->

        <div class="edit-mode" v-if="riskIsBeingEdited === risk.id">

            <form class="risk-edit-form" @submit.prevent="editConfirm($event, risk)">
                <v-textarea auto-grow rows="1" type="text" class="text-field" v-model="risk.section"
                    @keydown.enter="submitForm" />
                <div class="p-buttonset">
                    <Button icon="pi pi-pencil" class="edit" size="small" type="submit"
                        :title="$t('ListOfRisks.edit-risk')" />
                    <Button icon="pi pi-times" class="delete" size="small" @click="discardChanges(risk)"
                        :title="$t('ListOfRisks.discard-changes')" />
                </div>
            </form>

        </div>

        <div class="view-mode" v-else>

            <div class="content" @contextmenu="rightClick($event, risk)">
                <span v-if="risk.reliability == 'low'">*
                    <span class="explanation">{{ $t('ListOfRisks.reliability') }}</span>
                </span>
                <p class="motivation-pdf" v-if="risk.secure_url">
                    <!-- Keep the v-if here, the component should only render when the risks have to be visible
                    Removing the v-if will cause a visual bug-->
                    <vue-pdf-embed v-if="sourcesAreVisible" :source="risk.secure_url" :key="risk.uuid"></vue-pdf-embed>
                </p>
                <p v-else style="white-space: pre-line" class="motivation" :id="index"> {{ risk.section }}
                </p>
                <div class="footer">
                    <p v-if="risk.document_name" class="pagenumber unselectable">
                        {{ risk.document_name }}</p>
                    <p v-if="risk.page && risk.page !== null" class="pagenumber unselectable">
                        {{ $t('ListOfRisks.page') }}: {{ risk.page }}</p>
                </div>
            </div>

            <div class="p-buttonset">
                <Button icon="pi pi-pencil" class="edit" size="small" @click="setFieldToEdit($event, risk)"
                    :title="$t('ListOfRisks.edit-risk')" />
                <Button icon="pi pi-trash" class="delete" size="small" @click="deleteConfirm($event, risk)"
                    :loading="deleteIsLoading && selectedRiskForDeletion == risk.id"
                    loading-icon="pi pi-spinner pi-spin" :title="$t('ListOfRisks.delete-risk')" />

            </div>

        </div>

    </div>
    <div v-show="props.sourcesAreVisible" v-else class="risks-body">
        <p>{{ $t('ListOfRisks.select-doc') }}</p>
    </div>
    <ContextMenu ref="routemenu" :model="items" />
</template>

<script setup>
import { ref, computed, watch } from 'vue';

import Button from 'primevue/button';
import Divider from 'primevue/divider';
import VuePdfEmbed from 'vue-pdf-embed';
import { useRoute } from 'vue-router';

import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import ContextMenu from 'primevue/contextmenu';

import RightClickRisksFunctionality from '@/components/functional/RightClickRisksFunctionality.vue';
import { v4 as uuidv4 } from 'uuid';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';
import { updateDossier } from '@/helpers/axiosRequests';

const route = useRoute();

const props = defineProps({
    filteredRisks: Array,
    sourcesAreVisible: Boolean,
    dossier: Object
});

watch(props.dossier, (newValue, oldValue) => {
  console.log('props.dossier changed:', { newValue, oldValue });
});



const deleteIsLoading = ref(false);
// id or object of risk that will be edited will be assigned to this variable
const lastRiskSection = ref();
const selectedRiskForDeletion = ref();

// used for delete confirmation
const confirm = useConfirm();
const toast = useToast();

const selectedItems = ref([]);

// all selected risks have their id's in this array
const selectedRisks = computed(() => selectedItems.value.map(item => item.id));

const riskIsBeingEdited = ref();


const emit = defineEmits(['updateRisks', 'refreshSelectedItem', 'updateRisksListOnDelete', 'updateDossier']);

// generates a unique id for each risk that is not used in the db, and assigns it to the risk, so it can be used as a key and to keep track of the risk
props.filteredRisks.forEach(risk => {
    if (!risk.uuid) {
        risk.uuid = uuidv4();
    }
});

function submitForm(event) {
    event.target.form.dispatchEvent(new Event('submit', { cancelable: true }));
};

function discardChanges(item) {
    console.log(props.dossier)
    item.section = lastRiskSection.value;
    riskIsBeingEdited.value = null;
};

// adds appropriate classname to items, to show which items are selected and which are not
function showSelectedRisk(risk) {
    if (selectedRisks.value.includes(risk.id)) {
        return 'selected'
    } else {
        return
    }
}

function addRiskToSelection(event, risk) {
    // maybe replace shift with ctrl
    if (!event.shiftKey) {
        return
    }
    // if the user clicks on the same item, remove said item
    const existingItem = selectedItems.value.find(item => item.id === risk.id);
    if (existingItem) {
        selectedItems.value = selectedItems.value.filter(item => item.id !== risk.id);
    } else {
        // push that risk to selectedItems array
        selectedItems.value.push(risk);
    }
}

function setFieldToEdit(event, risk) {
    riskIsBeingEdited.value = risk.id;
    lastRiskSection.value = risk.section;
}


function editConfirm(event, risk) {
    // if no changes, return
    if (lastRiskSection.value === risk.section) {
        riskIsBeingEdited.value = "";
        return
    };
    axiosInstance.put('risks/' + risk.id + '/', risk)
        .then((response) => {
            // console.log('dossier', props.dossier);
            handleUpdateDossier([risk.name]);
            // // get the old risk and the new risk and assign them to a variable
            let oldRisk = lastRiskSection.value;
            let newRisk = risk.section;
            emit('updateRisks', risk);
            // // removes currently deleted tender from tenderList reactive object
            // emit('refreshSelectedItem');

        })
        .catch((error) => {
            //handle error
            devLog(error)
        })
        .then(() => {
            //always executed
            riskIsBeingEdited.value = '';
        });
}

async function bulkDelete(risksIDs, riskNames, type) {

    // makes object ready for post
    let deleteObject = {
        "dossier": parseInt(route.params.dossierId),
        "risk_ids": risksIDs
    }

    axiosInstance.post('delete-risks/', deleteObject)
        .then((response) => {
            // removes items from local data that have been deleted
            // remove items from props.filteredRisks that are in risksIDs
            emit('updateRisksListOnDelete', risksIDs, riskNames);
            handleUpdateDossier(riskNames);
            // updateSummariesChangedProperty(riskNames);
            selectedItems.value = [];
            if (type === 'keep') {
                toast.add({ severity: 'info', summary: 'Succes', detail: "De risico's werden succesvol bewaard.", life: 3000 });
            } else {
                toast.add({ severity: 'info', summary: 'Succes', detail: "De risico's werden succesvol verwijderd.", life: 3000 });
            }

        })
        .catch((error) => {
            //handle error
            devLog(error)
        })
        .then(() => {
            //always executed
            riskIsBeingEdited.value = '';
        });
}

// the riskIDs that get passed, are the ones the user has selected
// only the id's of the risks that are selected are passed
async function handleBulkDelete(risksIDs, type) {
    // get all risks that are selected according to risk ids
    const allRisks = await getSelectedRisks(risksIDs, type);
    // according to if the user has selected to keep or delete the selected risks, the function will return the risks that are to be deleted
    const selectedRiskIDs = await getSelectedRiskIDs(allRisks);
    // get the names of the risks that are selected
    const selectedRiskNames = await getSelectedRiskNames(allRisks);
    bulkDelete(selectedRiskIDs, selectedRiskNames, type);
}

async function getSelectedRisks(risks, type) {
    if (type === 'keep') {
        // if user wants to keep selected, then we need to select the risks the user did not select and delete those
        // all risks that are available in the list the user has selected
        // create let allRisks, and it needs to be a copy of props.filteredRisks, but without the risks that are selected
        return props.filteredRisks.filter(obj1 => !risks.includes(obj1.id));
    } else {
        return props.filteredRisks.filter(obj1 => risks.includes(obj1.id));
    }
}

async function getSelectedRiskIDs(risks) {
    let riskIDs = risks.map(risk => risk.id);
    return riskIDs;
}

async function getSelectedRiskNames(risks) {
    let riskNames = risks.map(risk => risk.name);
    // remove all duplicates from the array
    let uniqueRiskNames = [...new Set(riskNames)];
    return uniqueRiskNames;
}


// async function updateSummariesChangedProperty(riskNames) {
//     try {
//         await axiosInstance.put('summaries/bulk_update/', {
//             dossier: route.params.dossierId,
//             risk_name: riskNames
//         });
//     } catch (error) {
//         console.error(error);
//     }
// }


function deleteConfirm(event, risk) {
    selectedRiskForDeletion.value = risk.id
    confirm.require({
        // target: event.currentTarget,
        message: 'Weet u zeker dat u deze risico wilt verwijderen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nee',
        acceptClass: 'confirm-delete',
        accept: () => {
            deleteIsLoading.value = true;
            deleteRisk(risk);

        },
        reject: () => {
            toast.add({ severity: 'error', summary: 'Geannuleerd', detail: 'De risico werd niet verwijderd', life: 3000 });
        }
    });
}

function deleteRisk(risk) {
    axiosInstance.delete('risks/' + risk.id + '/')
        .then((response) => {
            // removes currently deleted tender from tenderList reactive object
            emit('updateRisks', risk)
            handleUpdateDossier([risk.name]);
            // shows delete succeeded notification
            deleteIsLoading.value = false;
            toast.add({ severity: 'info', summary: 'Succes', detail: 'De risico werd succesvol verwijderd.', life: 3000 });
            emit('refreshSelectedItem');
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
}

function handleUpdateDossier(riskNames) {
    console.log('props.dossier', props.dossier);

    // // Get the changed_warnings and changed_summaries from the dossier
    let theDossier = { ...props.dossier };

    // Add the riskNames to the changed_warnings and changed_summaries
    // exclude the riskNames that are already in the changed_warnings and changed_summaries
    riskNames.forEach(riskName => {
        if (!theDossier.changed_warnings.includes(riskName)) {
            theDossier.changed_warnings.push(riskName);
        }
        if (!theDossier.changed_summaries.includes(riskName)) {
            theDossier.changed_summaries.push(riskName);
        }
    });

    console.log('theDossier after filter', theDossier);
    console.log('if statement is', JSON.stringify(theDossier) === JSON.stringify(props.dossier));

    // // if theDossier and props.dossier are the same, return
    // if (JSON.stringify(theDossier) === JSON.stringify(props.dossier)) {
    //     return
    // }

    updateDossier(theDossier);
    emit('updateDossier', theDossier);
}

// rightclick functionality
const rightClickEvent = ref(null);
const rightClickData = ref(null);

// RIGHT CLICK: sets data in variable
function rightClick(event, data) {
    rightClickData.value = data;
    rightClickEvent.value = event;
}

// contextmenu variables
const routemenu = ref();
const items = ref([
]);




</script>

<style scoped>
.edit-mode,
.view-mode {
    /* background-color: white; */
    border-radius: 15px;
    padding: 0.5rem;
    padding-left: 1rem;
}

.button-field {
    display: flex;
    justify-content: flex-end;
}

.risk-edit-form {
    display: flex;
    width: 100%;
    height: 100%;
}

.view-mode,
.risk.edit-form {
    display: flex;
}

.edit-mode,
.view-mode {
    /* background-color: white; */
    border-radius: 15px;
    padding: 0.5rem;
    padding-left: 1rem;
}

.text-field {
    color: var(--main-dark-purple);
    font-style: italic;
    white-space: pre-line;
    text-align: left;
}

.content {
    flex-grow: 4;
    max-height: fit-content;
}

.motivation-pdf {
    max-height: 100vh;
    overflow: auto;
}

.pagenumber {
    color: var(--sec-dark-purple);
    font-size: 10px;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: start;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    font-size: 14px;
    color: var(--sec-dark-purple);
}

.risks-body {
    padding: 1rem;
    border-bottom: 1px solid #95A0EC;
    text-align: start;
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
    padding-bottom: 2rem;
    height: fit-content;
}

.selected {
    background-color: #e9ecff;
}
</style>