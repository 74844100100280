<template>
    <!-- hides header on login page, because you need to be logged in to see the header -->
    <the-header v-if="store.getters.isAuthenticated"></the-header>

    <div class="app">
        <the-sidebar v-if="store.getters.isAuthenticated"></the-sidebar>
        <Toast />
        <ConfirmDialog></ConfirmDialog>
        <!-- TODO: find a way to incorporate both ConfirmDialog and ConfirmPopup without causing bugs -->
        <!-- <ConfirmPopup></ConfirmPopup> -->

        <router-view class="main"></router-view>
    </div>
    <!-- shows auth page outside the "app" class, so it won't be inside of the flexbox -->
</template>

<script setup>
import TheHeader from '../src/components/layout/TheHeader.vue';
import TheSidebar from './components/layout/TheSidebar.vue';
// import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';

import { useStore } from 'vuex';
import { watch } from 'vue';

const store = useStore();

//calls autoLogin action, so user doesn't sign out on page refresh
store.dispatch('autoLogin');

//* this is used in store/dossiers, which is now archiveddddd
// // if user is authenticated
// if (store.getters.isAuthenticated) {
//     //then on refresh, also checks if there are any summaries in the dossiers that need to be updated
//     store.dispatch('initiateCheckingForSummariesInDossiers');
// }

// // if user logs in, then also checks if there are any summaries in the dossiers that need to be updated
// watch(() => store.getters.isAuthenticated, (newIsAuthenticated) => {
//     if (newIsAuthenticated) {
//         store.dispatch('initiateCheckingForSummariesInDossiers');
//     }
// });

</script>

<style>
/* importing the fonts */
/* CSS reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./assets/fonts/sourcesanspro-light-webfont.woff2') format('woff2'),
        url('./assets/fonts/sourcesanspro-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./assets/fonts/sourcesanspro-lightit-webfont.woff2') format('woff2'),
        url('./assets/fonts/sourcesanspro-lightit-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./assets/fonts/sourcesanspro-regular-webfont.woff2') format('woff2'),
        url('./assets/fonts/sourcesanspro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./assets/fonts/sourcesanspro-it-webfont.woff2') format('woff2'),
        url('./assets/fonts/sourcesanspro-it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./assets/fonts/sourcesanspro-semibold-webfont.woff2') format('woff2'),
        url('./assets/fonts/sourcesanspro-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./assets/fonts/sourcesanspro-semiboldit-webfont.woff2') format('woff2'),
        url('./assets/fonts/sourcesanspro-semiboldit-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./assets/fonts/sourcesanspro-bold-webfont.woff2') format('woff2'),
        url('./assets/fonts/sourcesanspro-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./assets/fonts/sourcesanspro-boldit-webfont.woff2') format('woff2'),
        url('./assets/fonts/sourcesanspro-boldit-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./assets/fonts/sourcesanspro-black-webfont.woff2') format('woff2'),
        url('./assets/fonts/sourcesanspro-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./assets/fonts/sourcesanspro-blackit-webfont.woff2') format('woff2'),
        url('./assets/fonts/sourcesanspro-blackit-webfont.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}


/* Global CSS variables */
:root {
    --main-dark-purple: #1A224C;
    --main-light-purple: #BFC7FF;
    --main-header-color: var(--main-dark-purple);
    --main-background-color: var(--main-light-purple);
    --sec-dark-purple: #3F4A86;
    --sec-light-purple: #6D76B4;
    --sidebar-width: 11rem;
    --font-family: 'Source Sans Pro', sans-serif;
}


/* classes that are defined in App.vue can be used everywhere */
#app {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #1A224C;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
}

body {
    background-color: #F1F3FF;
}

.square-card-page {
    border-radius: 0;
}

.card-title {
    color: white;
    background-color: var(--main-header-color);
    padding: 1rem;
}

.app {
    display: flex;
}

.app .main {
    flex: 1 1 0;
}

.title-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    padding-bottom: 3rem;
    align-items: flex-start;
}

</style>
