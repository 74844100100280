import axiosInstance from "@/services/interceptor";

export function updateDossier(newDossier) {
  axiosInstance
    .put("dossier-edit/", newDossier)
    .then((response) => {
        console.log('response.data',  response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}
