import { createRouter, createWebHistory, RouterView } from "vue-router";
import { h } from "vue";

import DossiersView from "@/views/DossiersView.vue";
import AuthLoginView from "@/views/AuthLoginView.vue";
import SSOCallback from "@/views/SSOCallback.vue";
import AuthLoginMfaView from "@/views/AuthLoginMfaView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import MySelectionsView from "@/views/MySelectionsView.vue";
import AddSelectionView from "@/views/AddSelectionView.vue";
import UsersView from "@/views/UsersView.vue";
import AddUsersView from "@/views/AddUsersView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import DashboardView from "@/views/DashboardView.vue";
import AddDossierView from "@/views/AddDossierView.vue";
import DetailedAnalysisView from "@/views/DetailedAnalysisView.vue";
import KeywordsView from "@/views/KeywordsView.vue";
import AddKeywordsView from "@/views/AddKeywordsView.vue";
import OverviewActionsView from "@/views/OverviewActionsView.vue";
import HandoverView from "@/views/HandoverView.vue";
import SearchView from "@/views/SearchView.vue";
import DashboardUploadView from "@/views/DashboardUploadView.vue";
import SummaryRisksView from "@/views/SummaryRisksView.vue";
import QualificationView from "@/views/QualificationView.vue";
import ConformityView from "@/views/ConformityView.vue";
import PdfRenderView from "@/views/PdfRenderView.vue";
import RapportQualificationView from "@/views/RapportQualificationView.vue";
import RapportAnalysisView from "@/views/RapportAnalysisView.vue";

import store from "@/store/index.js";

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: AuthLoginView,
    meta: { requiresAuth: false },
  },
  {
    path: "/auth/mfa",
    name: "auth-mfa",
    component: AuthLoginMfaView,
    meta: { requiresAuth: false },
  },
  {
    path: "/auth/callback",
    name: "auth-callback",
    component: SSOCallback,
    meta: { requiresAuth: false },
  },
  {
    path: "/",
    redirect: "/dossiers",
    meta: { requiresAuth: true },
  },
  {
    path: "/dossiers",
    // This is used for unrelated but similar routes
    // this is used so the router-link-active can target sub-routes like '/dossiers/add-dossier' as well
    component: { render: () => h(RouterView) },
    meta: { requiresAuth: true },
    // now all routes and components can be added in the children
    children: [
      {
        // the one with path '', is actually using path '/dossiers
        path: "",
        name: "dossiers",
        component: DossiersView,
        meta: { requiresAuth: true },
      },
      {
        path: "add-dossier",
        name: "addDossier",
        component: AddDossierView,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/selections",
    // This is used for unrelated but similar routes
    // this is used so the router-link-active can target sub-routes like '/dossiers/add-dossier' as well
    component: { render: () => h(RouterView) },
    meta: { requiresAuth: true },
    // now all routes and components can be added in the children
    children: [
      {
        path: "",
        name: "selections",
        component: MySelectionsView,
        meta: { requiresAuth: true },
      },
      {
        path: "add-selection",
        name: "addSelection",
        component: AddSelectionView,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/users",
    // This is used for unrelated but similar routes
    // this is used so the router-link-active can target sub-routes like '/dossiers/add-dossier' as well
    component: { render: () => h(RouterView) },
    meta: { requiresAuth: true },
    // now all routes and components can be added in the children
    children: [
      {
        path: "",
        name: "users",
        component: UsersView,
        meta: { requiresAuth: true },
      },
      {
        path: "add-users",
        name: "addUsers",
        component: AddUsersView,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/dashboard/:dossierId",
    // This is used for unrelated but similar routes
    // this is used so the router-link-active can target sub-routes like '/dossiers/add-dossier' as well
    component: { render: () => h(RouterView) },
    meta: { requiresAuth: true },
    // now all routes and components can be added in the children
    children: [
      {
        path: "",
        name: "dashboard",
        component: DashboardView,
        meta: { requiresAuth: true },
      },
      {
        path: "export-analysis/",
        name: "export-analysis",
        component: RapportAnalysisView,
        meta: { requiresAuth: true },
      },
      {
        path: "export-qualification/",
        name: "export-qualification",
        component: RapportQualificationView,
        meta: { requiresAuth: true },
      },
      {
        path: "upload/",
        name: "upload",
        component: DashboardUploadView,
        meta: { requiresAuth: true },
      },
      {
        path: "detailed-analysis",
        name: "detailed-analysis",
        component: DetailedAnalysisView,
        meta: { requiresAuth: true },
      },
      {
        path: "search",
        name: "search",
        component: SearchView,
        meta: { requiresAuth: true },
      },
      {
        path: "actions",
        name: "actions",
        component: OverviewActionsView,
        meta: { requiresAuth: true },
      },
      {
        path: "handover",
        name: "handover",
        component: HandoverView,
        meta: { requiresAuth: true },
      },
      {
        path: "summary-risks",
        name: "summary-risks",
        component: SummaryRisksView,
        meta: { requiresAuth: true },
      },
      {
        path: "qualification",
        name: "qualification",
        component: QualificationView,
        meta: { requiresAuth: true },
      },
      {
        path: "conformity",
        name: "conformity",
        component: ConformityView,
        meta: { requiresAuth: true },
      },
      {
        path: "pdf-render",
        name: "pdf-render",
        component: PdfRenderView,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/keywords",
    component: { render: () => h(RouterView) },
    meta: { requiresAuth: true },
    // now all routes and components can be added in the children
    children: [
      {
        path: "",
        name: "keywords",
        component: KeywordsView,
        meta: { requiresAuth: true },
      },
      {
        path: "add-keywords",
        name: "addKeywords",
        component: AddKeywordsView,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/reset-password/:token/",
    name: "resetPassword",
    component: ResetPasswordView,
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPasswordView,
  },
  {
    path: "/:notFound(.*)",
    name: "notFound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    // Save the intended URL in localStorage
    localStorage.setItem("intendedUrl", to.fullPath);
    next({ name: "auth" });
  } else if (to.meta.requiresAuth === false && store.getters.isAuthenticated) {
    // if user logged in, gets redirected to home when trying to access
    next({ name: "dossiers" });
  } else if (to.fullPath === "/auth" && store.getters.isAuthenticated) {
    next({ name: "dossiers" });
  } else {
    next();
  }
});


// exporting routes for tests
export { routes };
// exporting router for main.js
export default router;
